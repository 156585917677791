import React from "react";
import './style.css';

export const LoadingAnim = () => {
    return (
        <div className="row">
            <div className='d-flex justify-content-center'>
                <span className='loader-animation' />
            </div>
        </div>
    )
};