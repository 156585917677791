import reactPreset from '@bbob/preset-react/es'
import { getUniqAttr } from '@bbob/plugin-helper';
import {Spoiler} from "../components/Spoiler";
import { Code } from "../components/Code";

export const BBCodePreset = reactPreset.extend((tags, options) => ({
    ...tags,
    quote: node => ({
        tag: "blockquote",
        attrs: {className: "blockquote bg-white border ml-2 mt-2 mb-2 p-2", style: {fontSize: "1rem"}},
        content: [
            {
                tag: "span", 
                attrs: {className: "fa fa-quote-left fa-2x mr-2 d-md-inline", style: {opacity: .1}},
                content: " "
            },
            {
                tag: "i", 
                attrs: {}, 
                content: getUniqAttr(node.attrs) ? getUniqAttr(node.attrs) + " wrote :" : ""
            },
            {
                tag: "div", 
                attrs: {className: "clearfix"}, 
                content: node.content
            },
        ]
    }),
    youtube: node => ({
        tag: "div",
        attrs: {className: "ratio ratio-16x9"},
        content: [
            {
                tag: "iframe",
                attrs: {
                    id: "ytplayer", 
                    type: "text/html", 
                    src: `https://www.youtube.com/embed/${node.content}`, 
                    frameBorder: "0",
                    className: "embed-responsive-item",
                    allowFullScreen: "allowFullScreen"
                },
                content: ""
            }
        ]
    }),
    spoiler: node => ({
        tag: Spoiler,
        attrs: {content: `${node.content}`, bbcodeReparse: true},
        content: [
            ...node.content
        ]
    }),
    size: node => ({
        tag: "span",
        attrs: {style: {fontSize: `${(getUniqAttr(node.attrs) ?? "100") + "%"}`, lineHeight: "normal"}},
        content: node.content
    }),
    code: node => ({
        tag: Code,
        attrs: {content: `${node.content.reduce((r, node) => r + node.toString(), '')}`} // Copied from BBob library
    }),
    table: node => ({
        tag: "table",
        attrs: {className: "table table-striped"},
        content: [
            {
                tag: 'tbody',
                attrs: {},
                content: node.content
            }
        ]
    }),
    color: node => ({
        tag: "div",
        attrs: {style: {color: getUniqAttr(node.attrs)}},
        content: node.content
    })
 }));