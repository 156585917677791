import { useRef } from "react";

interface CodeProps {
    content: string
}

// TODO: figure out select
// <strong>Code: <a href="#" onClick={}>Select all</a></strong>
export const Code = ({ content }: CodeProps) => {
    //const contentRef = useRef(null);
    return (
        <div className="codebox mt-2">
            <pre className="pre-scrollable bg-dark text-light border border-secondary mb-2 p-2">
                <code>
                    {content}
                </code>
            </pre>
        </div>
    )
};