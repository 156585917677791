// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-animation {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #6a95a5; /* Blue */
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 20px;
    animation: spin 0.5s linear infinite;
  }
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}`, "",{"version":3,"sources":["webpack://./src/js/components/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,eAAe;IAC1C,6BAA6B,EAAE,SAAS;IACxC,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,oCAAoC;EACtC;;AAEF;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":[".loader-animation {\n    border: 4px solid #f3f3f3; /* Light grey */\n    border-top: 4px solid #6a95a5; /* Blue */\n    border-radius: 50%;\n    width: 35px;\n    height: 35px;\n    margin: 20px;\n    animation: spin 0.5s linear infinite;\n  }\n  \n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
