import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { LoadingAnim } from "../components/Loading";
import { getAcp } from "../utils/api";
import BBCode from '@bbob/react/es/Component';
import { BBCodePreset } from '../utils/bbcode';

interface NewsPost {
    id: number,
    time: string,
    title: string,
    message: string,
    hyperlink: string
}

const Root = () => (
    <App />
);

const App = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newsPosts, setNewsPosts] = useState<NewsPost[]>();
    const [currentPost, setCurrentPost] = useState<NewsPost>(null);
    const [error, setError] = React.useState<string | null>(null);
    const headerRef = React.useRef<HTMLHeadingElement>(null);
    const testRef = React.useRef<HTMLDivElement>(null);

    const loadNewsPosts = async () => {
        setIsLoading(true);
        const news: NewsPost[] = await getAcp("news");

        setNewsPosts(news.map((newsPost) => {
            newsPost.time = new Date(newsPost.time).toDateString();
            return newsPost;
        }));

        setIsLoading(false);
    }

    const handleClickNewsPost = (id: number) => {
        let newsPost: NewsPost = newsPosts.find((newsPost) => newsPost.id == id);

        if(newsPost != undefined)
        {
            setCurrentPost(newsPost);
        }
        else
        {
            setError("Couldn't retrieve selected post. Try to refresh the page.");
        }
    }

    const handleReturnButton = () => {
        setCurrentPost(null);
        window.scrollTo(window.scrollX, window.screen.height);
    }

    React.useEffect(() => {
        loadNewsPosts();
    }, [])

    if(newsPosts && newsPosts.length == 0)
        return <Alert>No news.</Alert>

    return <>
        <h1 ref={headerRef}>What we've been up to<span className="dot">.</span></h1>
        { isLoading && <LoadingAnim /> }
        <div className="row home-news">
        { newsPosts && !currentPost && !error && newsPosts.map((newsPost) => (
            <div ref={testRef} className="col-sm-12 col-md-4" key={newsPost.id}>
                <div className="card card-spacing">
                    <img src="//static.ls-rcr.com/media/news-article.jpg" className="card-img-top\" alt={newsPost.title} />
                    <div className="card-body">
                        <h5 className="card-title">{newsPost.title}</h5>
                        <hr /><div><a onClick={() => handleClickNewsPost(newsPost.id)} className="btn btn-block btn-primary">Read More</a><span className="badge badge-xs bg-dark float-end">{newsPost.time}</span></div>
                    </div>
                </div>
                <iframe ></iframe>
            </div>
        ))}

        { currentPost && !error &&
            <div>
                <h2 id="home-news-post-header">{currentPost.title}</h2>
                <Button onClick={() => handleReturnButton()} >Return</Button>
                <div id="news-container" className="type1" style={{whiteSpace: "pre-wrap"}}>
                    <BBCode plugins={[BBCodePreset()]} container={'div'}>
                        {currentPost.message}
                    </BBCode>
                </div>
                <Button onClick={() => handleReturnButton()} >Return</Button>
            </div>
        }

        { error && 
            <Alert
                variant="warning"
                dismissible
                onClose={() => setError(null)}
            >

                {error.toString()}
            </Alert>
        }
        </div>
    </>
};

export default Root;