import { useRef } from "react";
import bbobReactRender from '@bbob/react/es/render'
import { BBCodePreset } from "../utils/bbcode";

interface SpoilerProps {
    content: string,
    bbcodeReparse: boolean
}


export const Spoiler = ({ content, bbcodeReparse }: SpoilerProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const spanRef = useRef<HTMLSpanElement>(null);

    let handleSpoilerClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (contentRef.current.style.display != "") {
            contentRef.current.style.display = "";
            spanRef.current.innerHTML =
            "<b>Spoiler: </b><a style='cursor: pointer;' onclick='return false;'>hide</a>";
        } else {
            contentRef.current.style.display = "none";
            spanRef.current.innerHTML =
            "<b>Spoiler: </b><a style='cursor: pointer;' onclick='return false;'>show</a>";
        }
    }

    return (<div style={{padding: '3px', backgroundColor: '#FFFFFF', border: '1px solid #d8d8d8', fontSize: '1em'}}>
        <div style={{textTransform: 'uppercase', borderBottom: '1px solid #CCCCCC', marginBottom: '3px', fontSize: '0.8em', fontWeight: 'bold', display: 'block'}}>
            <span ref={spanRef} onClick={handleSpoilerClick}>
                <b>Spoiler: </b>
                <a style={{cursor: "pointer"}} onClick={() => false}>show</a>
            </span>
        </div>
        <div className="quotecontent">
            <div ref={contentRef} style={{display: 'none'}}>
                {!bbcodeReparse && content}
                {bbcodeReparse && bbobReactRender(content, BBCodePreset())}
            </div>
        </div>
    </div>)
};