import config from 'config';

const baseUrl = {
  acpApi: `${config.acpApi.host}/api`
};

export const getAcp = async <T extends unknown = any> (resource: string, token?: string): Promise<T> => {
    const authorizationHeader = token == undefined || token.length == 0 ? {} : {Authorization: `Bearer ${token}`}
    const result = await fetch(`${baseUrl.acpApi}/${resource}`, {
      headers: authorizationHeader
    });
    if (!result.ok) {
        throw result.status;
    }
    const resultJson = await result.json();

    return resultJson as T;
};

export const postAcp = async (url: string, token: string, data: any) => {
    const result = await fetch(`${baseUrl.acpApi}/${url}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    });
    if (result.ok) {
        let resultJson;
        try {
            resultJson = await result.json();
        } catch (err) { }
        return resultJson;
    } else {
        const text = await result.text();
        return { error: text, code: result.status };
    }
}
